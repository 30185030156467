// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ContentDeliveryNetworkcdnOnServer from "../../blocks/ContentDeliveryNetworkcdnOnServer/src/ContentDeliveryNetworkcdnOnServer";
import CfDrmIntegration2 from "../../blocks/CfDrmIntegration2/src/CfDrmIntegration2";
import AudioLibrary from "../../blocks/AudioLibrary/src/AudioLibrary";
import CfBandsRails from "../../blocks/CfBandsRails/src/CfBandsRails";
import AgeRestriction from "../../blocks/AgeRestriction/src/AgeRestriction";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Videos from "../../blocks/videos/src/Videos";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Crm3rdPartyIntegration from "../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration";
import AutomatedPublishing from "../../blocks/AutomatedPublishing/src/AutomatedPublishing";
import CountryCodeBlocking from "../../blocks/CountryCodeBlocking/src/CountryCodeBlocking";
import CfMultiMediaFileSupport2 from "../../blocks/CfMultiMediaFileSupport2/src/CfMultiMediaFileSupport2";
import ContextTagging from "../../blocks/ContextTagging/src/ContextTagging";
import Subtitles from "../../blocks/Subtitles/src/Subtitles";
import ImageThumbnail from "../../blocks/ImageThumbnail/src/ImageThumbnail";
import CfEncodeDecode3 from "../../blocks/CfEncodeDecode3/src/CfEncodeDecode3";
import VideoManagement from "../../blocks/VideoManagement/src/VideoManagement";



const routeMap = {
ContentDeliveryNetworkcdnOnServer:{
 component:ContentDeliveryNetworkcdnOnServer,
path:"/ContentDeliveryNetworkcdnOnServer"},
CfDrmIntegration2:{
 component:CfDrmIntegration2,
path:"/CfDrmIntegration2"},
AudioLibrary:{
 component:AudioLibrary,
path:"/AudioLibrary"},
CfBandsRails:{
 component:CfBandsRails,
path:"/CfBandsRails"},
AgeRestriction:{
 component:AgeRestriction,
path:"/AgeRestriction"},
UserGroups:{
 component:UserGroups,
path:"/UserGroups"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Videos:{
 component:Videos,
path:"/Videos"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Crm3rdPartyIntegration:{
 component:Crm3rdPartyIntegration,
path:"/Crm3rdPartyIntegration"},
AutomatedPublishing:{
 component:AutomatedPublishing,
path:"/AutomatedPublishing"},
CountryCodeBlocking:{
 component:CountryCodeBlocking,
path:"/CountryCodeBlocking"},
CfMultiMediaFileSupport2:{
 component:CfMultiMediaFileSupport2,
path:"/CfMultiMediaFileSupport2"},
ContextTagging:{
 component:ContextTagging,
path:"/ContextTagging"},
Subtitles:{
 component:Subtitles,
path:"/Subtitles"},
ImageThumbnail:{
 component:ImageThumbnail,
path:"/ImageThumbnail"},
CfEncodeDecode3:{
 component:CfEncodeDecode3,
path:"/CfEncodeDecode3"},
VideoManagement:{
 component:VideoManagement,
path:"/VideoManagement"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;